<template>
  <div class="container-footer">
    <div class="footer-warning">
      <div class="footer-title">合&nbsp;作&nbsp;伙&nbsp;伴</div>
      <!-- <div class="tips-item">
        <div class="tips-item-t">
          <div class="tips-item-t-icon icon-one"></div>
        </div>
        <div class="tips-text">商品价格低</div>
        <div class="tips-item-t">
          <div class="tips-item-t-icon icon-two"></div>
        </div>
        <div class="tips-text">减少人力成本</div>

        <div class="tips-item-t">
          <div class="tips-item-t-icon icon-three"></div>
        </div>
        <div class="tips-text">发货快</div>

        <div class="tips-item-t">
          <div class="tips-item-t-icon icon-four"></div>
        </div>
        <div class="tips-text">物流成本低</div>
      </div> -->
      <div class="delivery-img">
        <img src="~assets/images/index/zt.png" />
        <img src="~assets/images/index/bs.png" />
        <img src="~assets/images/index/em.png" />
        <img src="~assets/images/index/sf.png" />
        <img src="~assets/images/index/st.png" />
        <img src="~assets/images/index/tt.png" />
        <img src="~assets/images/index/yd.png" />
        <img src="~assets/images/index/yt.png" />
        <img src="~assets/images/index/yz.png" />
      </div>
    </div>

    <div class="footer-link" v-if="$store.getters.getSiteInfo">
      <div class="footer-link-left">
        <div class="footer-list">
          <div class="footer-logo">
            <img v-show="$store.getters.getSiteInfo" :src="$store.getters.getSiteInfo.logal" /> <span class="ztyc">{{ $store.getters.getSiteInfo.name }}</span
            ><span class="low-price">低价、安全、方便、高效.</span>
          </div>
          <div class="footer-word"></div>
        </div>
        <div>
          <span v-show="$store.getters.getSiteInfo">{{ $store.getters.getSiteInfo.name }}</span
          >历经多年沉淀,成功打造国内一流综合电商服务平台.
        </div>
        <div>
          <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">{{ $store.getters.getSiteInfo.beian }}</a>
        </div>
      </div>

      <div class="footer-link-center">
        <img class="img" v-show="$store.getters.getSiteInfo" :src="$store.getters.getSiteInfo.wechat_service" />
        <div></div>
        <div class="link-title">微信在线咨询</div>
      </div>

      <div class="footer-link-right">
        <div class="footer-right" @click="help">帮助中心</div>
        <div class="footer-right" @click="call">开发文档</div>
        <div class="footer-right">
          Copyright © 2021 <span v-show="$store.getters.getSiteInfo">{{ $store.getters.getSiteInfo.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  methods: {
    help() {
      this.$router.push('/notice')
    },
    call() {
      window.open(this.$store.getters.getSiteInfo.api_service, '_blank')
    }
  },
  mounted() {
    // 获取可视区域宽度
    const width = document.body.scrollWidth
    const app = document.querySelector('.container-footer')
    // console.log(app.style)
    app.style.width = width + 'px'
  }
}
</script>

<style lang="scss" scoped>
.container-footer {
  margin-top: 50px;
}
.footer-warning {
  .footer-title {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    font-size: 23px;
    color: gray;
    font-weight: 600;
  }
  .tips-item {
    display: flex;
    justify-content: center;
    color: gray;
    margin-bottom: 25px;
  }
  .delivery-img {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;

    img {
      border: 1px solid rgb(238, 230, 230);
      margin: 0px 5px;
      height: 50px;
    }
  }
}
.footer-link {
  padding: 30px;
  background-color: #0a46ec;
  color: white;
  display: flex;
  justify-content: center;

  .footer-link-left {
    .footer-list {
      display: flex;
      margin-bottom: 40px;
      .footer-logo {
        display: flex;
        img {
          height: 35px;
        }
        .ztyc {
          font-weight: 600;
          font-size: 22px;
        }
        .low-price {
          margin-left: 20px;
          margin-top: 10px;
          font: 500;
        }
      }
    }
    a {
      color: white;
    }
  }
  .footer-link-center {
    margin-left: 230px;
    .link-title {
      text-align: center;
    }
  }
  .footer-link-right {
    margin-left: 40px;
  }
  .img {
    width: 95px;
    height: 95px;
  }
  .footer-right {
    cursor: pointer;
    margin-bottom: 28px;
  }
}
.tips-text {
  padding: 25px;
}
.tips-item-t {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #feeee7;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tips-item-t-icon {
  width: 56px;
  height: 56px;
  background-image: url('~assets/images/sprites/css_sprites.png');
}
.tips-item-t-icon.icon-one {
  background-position: -430px -382px;
}

.tips-item-t-icon.icon-two {
  background-position: -514px -86px;
}

.tips-item-t-icon.icon-three {
  background-position: -514px -10px;
}

.tips-item-t-icon.icon-four {
  background-position: -514px -162px;
}
</style>
